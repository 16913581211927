import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    catchError,
    map,
    Observable,
    of,
    switchMap,
    throwError,
    timer,
} from 'rxjs';
import { getBookingUrl } from 'src/app/utilities/url.utils';
import { generateTimebasedId } from '@app/utilities/date.utils';
import {
    AvailabilityItemDto,
    CheckoutCartItem,
    CheckoutData,
    CheckoutGuest,
    OrderStatusDto,
    transformCartDtoToCheckoutData,
    transformVerifiedCartDtoToCheckoutData,
    VerifiedCartDto,
} from './checkout.model';
import { CartDto } from 'src/app/product-detail/booking-config/booking-config.model';
import { GeneralErrorService } from 'src/app/components/general-error/general-error.service';
import { UserService } from 'src/app/services/user.service';
import { AuthHttpClientNoXService } from 'src/app/services/auth-http-client-noX.service';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { CheckoutErrorsService } from './checkout-errors.service';
import * as _ from 'lodash';

interface ApplyDiscountPostBody {
    cartId: string;
    discountCode: string;
}

interface SetGuestsAndPaymentPostBody {
    paymentMethodId: number;
    guestData: {
        dealId: number;
        name: string;
        email: string;
        specialRequest: string;
        hidePrice: boolean;
    }[];
}

interface GetPeachCheckoutIdInput {
    orderId: string;
    amount: number;
    customerId: string;
    customerName: string;
    customerEmail: string;
}

@Injectable({
    providedIn: 'root',
})
export class CheckoutService {
    constructor(
        private authHttpClient: AuthHttpClientNoXService,
        private httpClient: HttpClient,
        private generalErrorService: GeneralErrorService,
        private userService: UserService,
        private currencyLanguageService: CurrencyLanguageService,
        private checkoutErrorService: CheckoutErrorsService
    ) {}

    updateCart(item: CheckoutCartItem): Observable<CheckoutData> {
        const url = getBookingUrl() + 'carts/items/' + item.id;
        return this.authHttpClient
            .put<CartDto, { quantity: number }>(url, { quantity: item.qty })
            .pipe(
                map((response) => transformCartDtoToCheckoutData(response)),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    setGuestsAndPaymentMethod(
        paymentId: number,
        guests: CheckoutGuest[]
    ): void {
        const { userCartId: cartId } = this.userService.getLocalCart();
        const url = getBookingUrl() + 'carts/' + cartId;
        this.authHttpClient
            .put<CartDto, SetGuestsAndPaymentPostBody>(url, {
                guestData: guests.map((guest) => {
                    return {
                        dealId: guest.dealId,
                        name: guest.name,
                        email: guest.email,
                        specialRequest: guest.guestrequest,
                        hidePrice: guest.remove_price,
                    };
                }),
                paymentMethodId: paymentId,
            })
            .pipe(
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            )
            .subscribe();
    }

    private handleError(
        error: HttpErrorResponse,
        checkoutData?: CheckoutData
    ): Observable<null> {
        let message = error?.error?.errorCode;
        if (this.checkoutErrorService.isKnownErrorCode(message)) {
            message =
                this.checkoutErrorService.getTranslatedErrorMessageFromErrorCode(
                    error.error,
                    checkoutData
                );
        } else {
            message = error?.error?.message;
        }

        this.generalErrorService.showGeneralError(message, {
            showMailto: false,
        });
        return of(null);
    }

    removeItemFromCart(item: CheckoutCartItem): Observable<CheckoutData> {
        const url = getBookingUrl() + 'carts/items/' + item.id;
        return this.authHttpClient.delete<CartDto, unknown>(url).pipe(
            map((response) => transformCartDtoToCheckoutData(response)),
            catchError((error: HttpErrorResponse) => this.handleError(error))
        );
    }

    getCartItems(): Observable<{
        checkoutData: CheckoutData;
        availability: AvailabilityItemDto[];
    }> {
        const { userCartId: cartId } = this.userService.getLocalCart();
        if (!cartId) {
            return of(null);
        }

        const url = getBookingUrl() + 'carts/' + cartId;

        return this.authHttpClient.get<VerifiedCartDto>(url).pipe(
            map((response) => {
                this.checkoutErrorService.constructNotAvailableDealNamesStringAndShowError(
                    response
                );
                return {
                    checkoutData:
                        transformVerifiedCartDtoToCheckoutData(response),
                    availability: response.availability,
                };
            }),
            catchError((error: HttpErrorResponse) => this.handleError(error))
        );
    }

    applyDiscountCode(code: string): Observable<CheckoutData> {
        const { userCartId: cartId } = this.userService.getLocalCart();
        const url = getBookingUrl() + 'discounts/apply';

        const postBody: ApplyDiscountPostBody = {
            cartId,
            discountCode: code,
        };

        return this.authHttpClient
            .post<CartDto, ApplyDiscountPostBody>(url, postBody)
            .pipe(
                map((response) => transformCartDtoToCheckoutData(response)),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    removeDiscountCode(code: string): Observable<CheckoutData> {
        const { userCartId: cartId } = this.userService.getLocalCart();
        const url = getBookingUrl() + 'discounts/unapply/code';

        const postBody: ApplyDiscountPostBody = {
            cartId,
            discountCode: code,
        };

        return this.authHttpClient
            .post<CartDto, ApplyDiscountPostBody>(url, postBody)
            .pipe(
                map((response) => transformCartDtoToCheckoutData(response)),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    placeOrder(
        paymentId: number,
        guests: CheckoutGuest[],
        checkoutData?: CheckoutData
    ): Observable<OrderStatusDto> {
        const { userCartId: cartId } = this.userService.getLocalCart();
        const url = getBookingUrl() + 'orders';

        const postBody = {
            cartId,
            paymentMethod: paymentId?.toString(),
            guestData: guests.map((guest) => {
                return {
                    dealId: guest.dealId,
                    name: guest.name,
                    email: guest.email,
                    specialRequest: guest.guestrequest,
                    hidePrice: guest.remove_price,
                };
            }),
        };

        return this.authHttpClient
            .post<OrderStatusDto, typeof postBody>(url, postBody)
            .pipe(
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error, checkoutData)
                )
            );
    }

    getMipsIframeUrl(orderId: string, amount: number): Observable<string> {
        // It's ok to use immediate here because this is triggered by the user so it's just whatever's set at the time
        const locale = this.currencyLanguageService.getLocaleDataImmediate();
        const { threeLetterCurrency, language } = locale;
        const url = getBookingUrl() + 'payment/zone';
        const customerid = this.userService.getCustomerOrUserId();
        const queryParams = `?orderId=${orderId}&amount=${amount}&currency=${threeLetterCurrency}&language=${language.toUpperCase()}&paymentMode=test&requestMode=simple&customerId=${customerid}`;
        return this.authHttpClient
            .get<{ paymentZone: string }>(`${url}${queryParams}`)
            .pipe(
                map((response) => response.paymentZone),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    applyGiftCode(code: string): Observable<CheckoutData> {
        const url = getBookingUrl() + 'gift-cards/apply';
        const { userCartId: cartId } = this.userService.getLocalCart();

        const postBody = {
            cartId,
            giftCardCode: code,
        };

        return this.authHttpClient
            .post<CartDto, typeof postBody>(url, postBody)
            .pipe(
                map((response) => transformCartDtoToCheckoutData(response)),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    removeGiftCode(code: string): Observable<CheckoutData> {
        const url = getBookingUrl() + 'gift-cards/unapply';
        const { userCartId: cartId } = this.userService.getLocalCart();

        const postBody = {
            cartId,
            giftCardCode: code,
        };

        return this.authHttpClient
            .post<CartDto, typeof postBody>(url, postBody)
            .pipe(
                map((response) => transformCartDtoToCheckoutData(response)),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    getStoreCreditAmount(): Observable<number> {
        const url = getBookingUrl() + 'store-credit/customer';
        return this.authHttpClient
            .get<{ storeCreditBalance: number }[]>(url)
            .pipe(
                map((response) =>
                    _.reduce(
                        response,
                        (acc, item) => acc + item.storeCreditBalance,
                        0
                    )
                ),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    applyStoreCredit(amount: number): Observable<CheckoutData> {
        const url = getBookingUrl() + 'store-credit';
        const { userCartId: cartId } = this.userService.getLocalCart();

        const postBody = {
            cartId,
            amount,
        };

        return this.authHttpClient
            .post<CartDto, typeof postBody>(url, postBody)
            .pipe(
                map((response) => transformCartDtoToCheckoutData(response)),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    removeStoreCredit(amount: number): Observable<CheckoutData> {
        const url = getBookingUrl() + 'store-credit';
        const { userCartId: cartId } = this.userService.getLocalCart();

        const postBody = {
            cartId,
            amount,
        };

        return this.authHttpClient
            .delete<CartDto, typeof postBody>(url, postBody)
            .pipe(
                map((response) => transformCartDtoToCheckoutData(response)),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    getRewardPointsBalance(): Observable<number> {
        const url = getBookingUrl() + 'reward-points';
        return this.authHttpClient
            .get<{ rewardPointsBalance: number }>(url)
            .pipe(
                map((response) => response.rewardPointsBalance),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    applyRewardPoints(rewardPointsToApply: number): Observable<CheckoutData> {
        const url = getBookingUrl() + 'reward-points';
        const { userCartId: cartId } = this.userService.getLocalCart();

        const postBody = {
            cartId,
            amount: rewardPointsToApply,
        };

        return this.authHttpClient
            .post<CartDto, typeof postBody>(url, postBody)
            .pipe(
                map((response) => transformCartDtoToCheckoutData(response)),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    removeRewardPoints(number: number): Observable<CheckoutData> {
        const url = getBookingUrl() + 'reward-points';
        const { userCartId: cartId } = this.userService.getLocalCart();

        const postBody = {
            cartId,
            amount: number,
        };

        return this.authHttpClient
            .delete<CartDto, typeof postBody>(url, postBody)
            .pipe(
                map((response) => transformCartDtoToCheckoutData(response)),
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    retryCheckMipsPaymentStatus(orderId: string): Observable<OrderStatusDto> {
        const retryIntervals = [
            3000, 3000, 3000, 5000, 5000, 5000, 5000, 5000, 10000, 10000, 10000,
            10000, 10000,
        ];
        let retryCount = 0;

        const checkStatus = (): Observable<OrderStatusDto> => {
            return this.checkPaymentStatus(orderId).pipe(
                switchMap((response) => {
                    if (retryCount < retryIntervals.length) {
                        if (
                            response.status === 'Completed' ||
                            response.status === 'Failed' ||
                            response.status === 'Payment_failed'
                        ) {
                            return of(response);
                        } else {
                            const delay = retryIntervals[retryCount];
                            retryCount++;
                            return timer(delay).pipe(
                                switchMap(() => checkStatus())
                            );
                        }
                    } else {
                        return throwError('Failed to check payment status');
                    }
                })
            );
        };

        return checkStatus();
    }

    checkAvailability(): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            this.authHttpClient
                .get<VerifiedCartDto>(
                    getBookingUrl() +
                        'carts/' +
                        this.userService.getLocalCart().userCartId
                )
                .subscribe((response) => {
                    if (response.notAvailableCartItems.length > 0) {
                        this.checkoutErrorService.constructNotAvailableDealNamesStringAndShowError(
                            response
                        );
                        observer.next(false);
                    } else {
                        observer.next(true);
                    }
                    observer.complete();
                });
        });
    }

    private checkPaymentStatus(orderId: string): Observable<OrderStatusDto> {
        return this.authHttpClient
            .get<OrderStatusDto>(
                `${getBookingUrl()}orders/${orderId}/payment-status`
            )
            .pipe(map((response) => response));
    }

    // TODO: To be moved to backend (done in lambda)
    // Sandbox credentials
    getPeachCheckoutId({
        orderId,
        amount,
        customerId,
        customerName,
        customerEmail,
    }: GetPeachCheckoutIdInput): Observable<string> {
        const authBaseUrl = 'https://sandbox-dashboard.peachpayments.com';
        const checkoutBaseUrl = 'https://testsecure.peachpayments.com';

        const bodyToken = {
            clientId: 'b315f99f8c51b2214a0cb799a702b6',
            clientSecret:
                'zekt1BsNwdJe1VLHFcUGVxvVvWT7Wc4b5TcsqRJymIwltGVcm01fjKtT4A99J4+vhgFMVNC5fyamoggQ3JbaXQ==',
            merchantId: '1e57d0f31e2c4d90810b001e0486512b',
        };

        const shopperResultUrl =
            location.hostname === 'localhost' // TODO: Redirect to proper page
                ? 'http://stg.marideal.mu/cart'
                : `${location.origin}/cart`;

        const customer = {
            merchantCustomerId: customerId,
            givenName: customerName,
            email: customerEmail,
        };

        const bodyCheckout = {
            'authentication.entityId': '8ac7a4ca89bff1b50189c08485f60141',
            merchantTransactionId: orderId,
            nonce: generateTimebasedId(),
            currency: 'MUR',
            amount,
            shopperResultUrl,
            customer,
        };

        return this.httpClient
            .post<{ access_token: string }>(
                `${authBaseUrl}/api/oauth/token`,
                bodyToken
            )
            .pipe(
                map((res) => res.access_token),
                switchMap((access_token) =>
                    this.httpClient.post<{ checkoutId: string }>(
                        `${checkoutBaseUrl}/v2/checkout`,
                        bodyCheckout,
                        {
                            headers: {
                                Authorization: `Bearer ${access_token}`,
                            },
                        }
                    )
                ),
                map((res) => res.checkoutId)
            );
    }
}
