import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export const parseDate = (dateString: string): NgbDateStruct => {
    const dateShards = dateString.split('-');
    return {
        year: Number(dateShards[0]),
        month: Number(dateShards[1]),
        day: Number(dateShards[2]),
    };
};

let counter = 0;

export const generateTimebasedId = (prefix = '') => {
    const now = new Date();
    const nowPerformance = performance.now();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const milliseconds = String(now.getMilliseconds()).padStart(3, '0');
    const submilliseconds = String(Math.floor((nowPerformance % 1) * 1000)).padStart(3, '0');

    counter++;

    return `${prefix}${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}${submilliseconds}${counter}`;
};
